import React from "react"
import {useSelector, useDispatch} from "react-redux"
import { useCookies } from "react-cookie"
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../../TC.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
const footerDesc = "";

const Wallet = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="Thank you for your interest!" subtitle="Access by invitation only, register to request your access." amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUpEVVAWallet"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());

  return (
    <Layout lang={appState.lang} footerDesc={footerDesc} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
      <SEO lang={appState.lang} page="wallet" title="Con EVVA wallet generas más clientes y más ventas para tu negocio" description="Con EVVA wallet puedes generar campañas digitales de lealtad, más ventas para tu negocio y ahorros para tus clientes."/>
      {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/>}
      {!cookies.consent &&
      <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
      }
      <MainSection title="Start. Control. Grow." description="<h3>Increase your customer loyalty through digital stamp cards and digital coupons.</h3>" position="left" image="hero-wallet.png" actionLabel="Join EVVA" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUpEVVAWallet">
        <AppBar pathname={appState.path}/>
      </MainSection>
      <PageSection maxColumns="6" title="Manage your loyalty program digitally" >
        <Card imageHeight="100px" image="digital-fit-business-icon.svg" description="<p>Fit any businesses</p>" textAlign="center" />
        <Card imageHeight="100px" image="digital-branded-loyalty-card-icon.svg" description="<p>Branded loyalty card</p>" textAlign="center" />
        <Card imageHeight="100px" image="digital-coupons-icon.svg" description="<p>Digital coupons and stamp card</p>" textAlign="center" />
        <Card imageHeight="100px" image="digital-direct-marketing-icon.svg" description="<p>Direct marketing via email & social media</p>" textAlign="center" />
        <Card imageHeight="100px" image="digital-crm-icon.svg" description="<p>CRM and Client tracking</p>" textAlign="center" />
        <Card imageHeight="100px" image="digital-bank-account-icon.svg" description="<p>Digital payments</p>" textAlign="center" />
      </PageSection>
      <PageSection maxColumns="4" title="Traditional loyalty program and marketing communication" bgColor="#F9F9F9" >
        <Card imageHeight="160px" imageWidth="240px" image="beaurocracy-icon.png" description="<p>High cost to print upfront, and not environmental friendly</p>" textAlign="center" descriptionColor="#363636" />
        <Card imageHeight="160px" imageWidth="240px" image="low-visibility-icon.png" description="<p>Hard to track the effect of the program, and understand the return of investment</p>" textAlign="center" descriptionColor="#363636" />
        <Card imageHeight="160px" imageWidth="240px" image="reach-existing-customer-icon.png" description="<p>Difficult to reach existing customers for marketing and communication</p>" textAlign="center" descriptionColor="#363636" />
        <Card imageHeight="160px" imageWidth="240px" image="difficult-find-wallet-icon.png" description="<p>Difficult to find in wallet. Physical stamps are easy to replicate</p>" textAlign="center" descriptionColor="#363636" />
      </PageSection>
      <PageSection maxColumns="2" title="Digital rewards with EVVA wallet" description="Issue Digital Rewards through EVVA Wallet, get new customers and help them save money.">
        <Card subtitle="Restaurant loyalty card" description="<p>You can reward your customers with exclusive members only dining offers and referral of their friends and family</p>" descriptionColor="#363636" image="burger-icon.png" horizontal padding shadow/>
        <Card subtitle="Yoga and fitness Loyalty Card" description="<p>Send sharable yoga or fitness session coupons to your customers. Reward your customers at any time</p>" descriptionColor="#363636" image="yoga-icon.png" horizontal padding shadow/>
        <Card subtitle="Coffee shop rewards" description="<p>Your customers can collect stamps and get a free coffee after any amount of purchaces you set. You can also issue daily or seasonal coupons and rewards</p>" descriptionColor="#363636" image="smoothie-icon.png" horizontal padding shadow/>
        <Card subtitle="Mom & Pop stores" description="<p>Send coupons and offers to your customers and reward them when they make many purchases in your store</p>" descriptionColor="#363636" image="pop-store-icon.png" horizontal padding shadow/>
        <Card subtitle="Hair salon loyalty card" description="<p>Your customers can collect stamps and get a free hair product or hair cut. You can also issue coupons and offers for them to share with friends and family</p>" descriptionColor="#363636" image="scissors-icon.png" horizontal padding shadow/>
        <Card subtitle="Massage and physiotherapy" description="<p>Gives offers to your customers to share with their friends and family. Reward them with sessions packages deals</p>" descriptionColor="#363636" image="massage-icon.png" horizontal padding shadow/>
      </PageSection>
      <SecondarySection
        title="Together we grow"
        shortDescription="From Entrepreneurs to entrepreneurs, we are people like you who understand your needs. Our ultimate goal is to let you focus on what really matters ... Your business."
        position="center"
        image="evva-together.jpg"
        actionLabel="Get Early Access"
        action={launchFormHandler}
        amplitude={appState.amplitude}
        amplitudeClickEvent="ClickPreSignUpEVVAWallet"
      />
    </Layout>
  )
};

export default Wallet